import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import CsodBlockContent from '../../molecules/BlockContent/BlockContent';
import Layout from '../../organisms/Layout/Layout';
import Section from '../../molecules/Section/Section';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import { breakpoints, unitConverter as uc, colors } from '../../../styles/base';
import PressContact from '../../molecules/PressContact/PressContact';
import CompanyDescription from '../../molecules/CompanyDescription/CompanyDescription';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import useLocalize from '../../../hooks/useLocalize';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from '../../atoms/Headings/Headings';

const containerCss = css`
  margin-top: ${uc('150px')};
  margin-bottom: ${uc('50px')};

  @media (${breakpoints.largeTablet}) {
    margin-bottom: ${uc('60px')};
  }
`;

const blockContentWrapperCss = css`
  margin: ${uc('0 80px 20px 20px')};

  ul {
    li {
      margin-left: ${uc('20px')};
      line-height: 1.4;
    }
  }

  p {
    line-height: 1.4;
  }
`;

const contactWrapperCss = css`
  margin: ${uc('20px')};
`;

const PressReleasePage = ({
  data: rawData,
  location,
  pageContext: { locale },
}) => {
  if (!rawData) {
    return null;
  }

  const data = useLocalize(rawData, locale);
  const pressContact = getOr(null, 'sanityNewsRoomPage.pressContact', data);
  const investorContact = getOr(
    null,
    'sanityNewsRoomPage.investorContact',
    data
  );
  const isInvestorRelease = getOr(
    null,
    'sanityPressReleasePage.isInvestorRelease',
    data
  );
  const companyDescriptions = getOr(
    null,
    'sanityPressReleasePage.companyDescriptions',
    data
  );

  const Headings = [Heading1, Heading2, Heading3, Heading4, Heading5, Heading6];

  const BlockRenderer = props => {
    /* eslint-disable-next-line react/prop-types */
    const { style = 'normal' } = props.node;

    // checks if block is an h1, h2, h3, etc.
    if (/^h\d/.test(style)) {
      // grabs the number from the h1, h2, h3, etc.
      const level = style.replace(/[^\d]/g, '');
      // selects the correct heading component
      const Heading = Headings[level - 1];
      return <Heading color={colors.mediumGray} {...props} />;
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };

  const serializers = {
    types: {
      block: BlockRenderer,
      // eslint-disable-next-line react/prop-types
      csodImage: ({ node: { asset } }) => (
        <Image
          src={sanityImage(asset)
            .maxWidth(`${uc('120rem')}`)
            .auto('format')
            .url()}
        />
      ),
    },
  };

  return (
    <Layout
      {...data.sanityPressReleasePage._rawRoute}
      href={location.href}
      sanityId={data._id}
      locale={locale}
    >
      <PageWrapper>
        <div css={containerCss}>
          <Section>
            <div css={blockContentWrapperCss}>
              <CsodBlockContent
                blocks={pipe(
                  getOr([], 'sanityPressReleasePage._rawSections'),
                  find(section => section._type === 'blockContentSection'),
                  getOr([], 'content')
                )(data)}
                serializers={serializers}
              />
            </div>
            {companyDescriptions && (
              <div css={blockContentWrapperCss}>
                {map(
                  ({ _id, _rawDescription, title }) => (
                    <CompanyDescription
                      key={_id}
                      title={title}
                      description={_rawDescription}
                    />
                  ),
                  companyDescriptions
                )}
              </div>
            )}
            {isInvestorRelease && investorContact && (
              <div css={contactWrapperCss}>
                <PressContact pressContact={investorContact} />
              </div>
            )}
            {pressContact && (
              <div css={contactWrapperCss}>
                <PressContact pressContact={pressContact} />
              </div>
            )}
          </Section>
        </div>
      </PageWrapper>
    </Layout>
  );
};

PressReleasePage.propTypes = {
  data: PropTypes.shape({ sanityPressReleasePage: {}, _id: PropTypes.string }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

PressReleasePage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export const query = graphql`
  query($slug: String) {
    sanityPressReleasePage(route: { slug: { current: { eq: $slug } } }) {
      title: _rawTitle(resolveReferences: { maxDepth: 100 })
      isInvestorRelease
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections
      companyDescriptions {
        _id
        title: _rawTitle(resolveReferences: { maxDepth: 100 })
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
      _id
    }
    sanityNewsRoomPage(
      route: { slug: { current: { eq: "/company/newsroom" } } }
    ) {
      pressContact {
        name: _rawName(resolveReferences: { maxDepth: 100 })
        phone: _rawPhone(resolveReferences: { maxDepth: 100 })
        title: _rawTitle(resolveReferences: { maxDepth: 100 })
        email: _rawEmail(resolveReferences: { maxDepth: 100 })
      }
      investorContact {
        name: _rawName(resolveReferences: { maxDepth: 100 })
        phone: _rawPhone(resolveReferences: { maxDepth: 100 })
        title: _rawTitle(resolveReferences: { maxDepth: 100 })
        email: _rawEmail(resolveReferences: { maxDepth: 100 })
      }
    }
  }
`;

export default PressReleasePage;
