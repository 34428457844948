import React from 'react';
import PropTypes from 'prop-types';
import CsodBlockContent from '../BlockContent/BlockContent';
import { Heading3 } from '../../atoms/Headings/Headings';

const CompanyDescription = ({ description, title }) => {
  return (
    <div>
      <Heading3>{title}</Heading3>
      <CsodBlockContent blocks={description} />
    </div>
  );
};

CompanyDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.instanceOf(Array).isRequired,
};

export default CompanyDescription;
